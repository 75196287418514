<template>
    <div class="col s12 m5  l5 xl4">
        <div>
            <div class="page-subtitle">
                <h4>Создать подкатегорию товара</h4>
            </div>

            <form @submit.prevent="submitHandler">

                <div class="input-field" >
                    <select ref="select" v-model="current">

                        <option v-for="c of categories" :key="c.id" :value="c.id"> {{ c.title }}</option>

                    </select>

                    <label>Выберите родительскую категорию</label>

                </div>

                <div class="input-field">

                    <input
                            id="name"
                            type="text"
                            v-model="title"
                            :class="{invalid: $v.title.$dirty && !$v.title.required}"
                    >

                    <label for="name">Название подкатегории </label>

                    <span  v-if="$v.title.$dirty && !$v.title.required"
                           class="helper-text invalid"

                    >
                        Введите название подкатегории
                    </span>
                </div>

                <div class="input-field">

                    <input
                            id="path"
                            type="text"
                            v-model="subCatPath"
                            :class="{invalid: $v.subCatPath.$dirty && !$v.subCatPath.required}"
                    >

                    <label for="name">Путь подкатегории по английски</label>

                    <span  v-if="$v.subCatPath.$dirty && !$v.subCatPath.required"
                           class="helper-text invalid"

                    >
                        Путь подкатегории по английски!
                    </span>
                </div>



                <button class="btn category-btn blue waves-effect waves-light" type="submit">
                    Создать
                    <i class="material-icons right">send</i>
                </button>
            </form>
        </div>
    </div>

</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    export default {
        name: 'create-subcategories',
        props: {
            categories: {
                type: Array,
                required: true
            }
        },
        data: () => ({

            select: null,
            current: null,
            subCatTitle : '',
            title: '',
            path: '',
            subCatPath: ''
        }),
        validations:{
            title: {required},
            subCatPath: {required},

        },

        watch:{
            current(categoryId){
                const {path} = this.categories.find(cat => cat.id === categoryId)
                console.log('subCatTitle' , path);
            }
        },
        //устанавливаем первоначальные значения в селект
        created() {
            const {id, title, path } = this.categories[0];
            this.current = id;
            this.title = '';
            this.subCatPath = '/'
        },

        mounted(){
            this.select = M.FormSelect.init(this.$refs.select);
            M.updateTextFields();
        },
        methods:{

            async submitHandler(){

                if (this.$v.$invalid){
                    this.$v.$touch();
                    return
                }

                try {
                    const category = await this.$store.dispatch('createSubcategory', {
                        id : this.current,
                        title : this.title,
                        path :    this.path  + this.subCatPath ,
                    });
                    this.title = '';

                    this.$v.$reset();
                    this.$message('Подкатегория успешно создана');
                    //родительский компонент прослушивает изменения дочернего
                    this.$emit('created', category)
                } catch (e) {

                }
            }
        },


        destroyed() {
            if (this.select && this.select.destroy) {
                this.select.destroy
            }
        }
    }
</script>