<template>
    <div class="col s12 m6 l5 xl4 offset-m1 offset-l2 offset-xl2 edit-subcategory ">
        <div>
            <div class="page-subtitle">
                <h4>Редактировать подкатегории товаров</h4>
            </div>

            <form >
                <div class="input-field" >
                    <select ref="select" v-model="current"  @change="kek" >

                        <option v-for="c in categories" :key="c.id" :value="c.id"

                        > <span class="black-text text-lighten-1">Категория "{{ c.title }}" с подкатегориями:  </span>

                            <div v-for="k in c.subcategories">
                                <span> {{k.title}},  </span>
                            </div>

                        </option>

                    </select>

                    <label>Выберите родительскую категорию</label>

                </div>



                <div v-if="subcats"  class="input-field" >
                    <select ref="subcatSelect" v-model="currentSubCat"  >

                        <option v-for="s in subcats"  :value="s.id" >{{s.title}} </option>

                    </select>

                    <label>Выберите подкатегорию</label>
                </div>



                <div class="input-field">

                    <input
                            id="name"
                            type="text"
                            v-model="title"
                            :class="{invalid: $v.title.$dirty && !$v.title.required}"
                    >

                    <label for="name">Название</label>

                    <span  v-if="$v.title.$dirty && !$v.title.required"
                           class="helper-text invalid"

                    >
                        Введите название категории
                    </span>
                </div>

                <div class="input-field">

                    <input
                            id="path"
                            type="text"
                            v-model="path"
                            :class="{invalid: $v.path.$dirty && !$v.path.required}"
                    >

                    <label for="name">Название</label>

                    <span  v-if="$v.path.$dirty && !$v.path.required"
                           class="helper-text invalid"

                    >
                        Введите название подкатегории
                    </span>
                </div>





                <button class="btn edit-category-btn blue waves-effect waves-light" @click.prevent="submitHandler">
                    Обновить
                    <i class="material-icons right">send</i>
                </button>

                <button class="btn btn-category-delete red waves-effect waves-light" @click.prevent="submitRemove">
                    <i class="material-icons right">delete</i>
                </button>
            </form>
        </div>
    </div>
</template>

<script>

    import {required} from 'vuelidate/lib/validators'

    export default {

        name: 'edit-subcategory',
        props: {
            categories: {
                type: Array,
                required: true
            },
        },
        data: () => ({
            updateCount: 1,
            select: null,
            title: '',
            path: '',
            current: null,
            currentSubCat: null,
            subcatSelect: null,
            loading: true,


            subcats: [],

            subCatId: null
        }),
        validations:{
            title: {required},
            path: {required},

        },

        computed:{

        },
        watch:{


            current(categoryId){

                this.catId = categoryId;

                try{
                    const {title, path, id, subcategories} = this.categories.find(cat => cat.id === this.catId);

                    this.subcategories = subcategories;

                    this.path = path;
                    // console.log('this.subcategories', this.subcategories);
                    this.subcats  = Object.keys(this.subcategories).map(key => ({...this.subcategories[key], id: key}))
                    console.log('this.subcategories', this.subcats );

                    setTimeout(()=>{
                        this.subcatSelect = M.FormSelect.init(this.$refs.subcatSelect);
                        M.updateTextFields()
                    },0);

                }catch(e){
                    console.log(e)
                }
            },

            currentSubCat(subCategoryId){
                this.subCatId = subCategoryId;

                try {
                    if ( this.subcats){
                        const {title, path, id} =  this.subcats.find(cat => cat.id === this.subCatId);
                        this.title = title;
                        this.path = path;
                    } else {
                        this.title ='kek'
                        this.path = 'kek';
                    }

                }catch (e) {
                    console.log(e)
                }


            }
        },
        //устанавливаем первоначальные значения в селект
        created() {
            const {id, title, path } = this.categories[0];
            this.current = id;
            this.currentSubCat = id;
            this.title = title;
            this.path = path;
        },

        async mounted(){
            this.updateCount = Math.floor(Math.random() * 30000)
            console.log(this.updateCount, 'this.updateCount!!!')

            setTimeout(()=>{
                this.select = M.FormSelect.init(this.$refs.select);
                M.updateTextFields()
            },0);

        },

        methods:{

            kek(){

                this.updateCount = Math.floor(Math.random() * 30000)
                this.subcatSelect = M.FormSelect.init(this.$refs.subcatSelect);

            },
            async submitHandler(){
                if (this.$v.invalid){
                    this.$v.$touch()
                    return;
                }

                try {
                    const categoryData = {
                        title: this.title,
                        path: this.path,
                        id : this.current,
                        sid : this.currentSubCat
                    };
                    //передаем объект категории для обновления на сервак
                    await this.$store.dispatch('updateSubCategory', categoryData);
                    this.$message('Подкатегория обновлена');
                    // обновили данные на бэкэнде, надо передать в родителя, чтобы отрисовать опшнс в селекте
                    this.$emit('updatedCategory', categoryData)
                }   catch (e) {

                }
            },

            async submitRemove(){
                if (this.$v.invalid){
                    this.$v.$touch()
                    return;
                }
                try {
                    const categoryData = {
                        id : this.current,
                        sid : this.currentSubCat,
                    };
                    //передаем объект категории для обновления на сервак
                    await this.$store.dispatch('deleteSubCategory', categoryData);
                    this.$message('Подкатегория удалена');
                    // обновили данные на бэкэнде, надо передать в родителя, чтобы отрисовать опшнс в селекте
                    this.$emit('updatedCategory', categoryData)
                }   catch (e) {
                        console.log(e)
                }
            },


        },
        destroyed() {
            if (this.select && this.select.destroy) {
                this.select.destroy
            }
        }
    }
</script>